import serviceAxios from "./index";

// 翻译
export const translate = (data) => {
    return serviceAxios({
        url: `/translator/translate`,
        method: "post",
        data
    })
}

// 图片替换
export const replace = (data) => {
    return serviceAxios({
        url: `file/replace`,
        method: "post",
        data
    })
}

// 术语表
export const getTerminology = () => {
    return serviceAxios({
        url: `/terminology`,
        method: "get"
    })
}

// 文章总结
export const summarize = (data) => {
    return serviceAxios({
        url: `summarize`,
        method: "post",
        data
    })
}

// 通过url获取文章内容
export const getLinkContent = (data) => {
    return serviceAxios({
        url: `article/fromUrl`,
        method: "post",
        data
    })
}

// 发布文章
export const articleCreate = (data) => {
    return serviceAxios({
        url: `article/create`,
        method: "post",
        data
    })
}

// 获取社区用户
export const articleUsers = (name) => {
    return serviceAxios({
        url: `article/users?name=${name}`,
        method: "get",
    })
}