import { useState, useEffect, useCallback } from 'react';

function useDebounce(fn, delay) {
  const [timer, setTimer] = useState(null);

  const debouncedFn = useCallback(
    (...args) => {
      if (timer) {
        clearTimeout(timer);
      }
      setTimer(
        setTimeout(() => {
          fn(...args);
        }, delay)
      );
    },
    [fn, delay, timer]
  );

  useEffect(
    () => () => {
      clearTimeout(timer);
    },
    [timer]
  );

  return debouncedFn;
}

export default useDebounce;
