import { useState } from "react";
import "./index.scss";
import { articleCreate, articleUsers } from "../../request/api";

export default function CustomCreateModal({inputTitle,setInputTitle,inputOriginUrl,setInputOriginUrl,value,setShowCreateDialog,createType}) {

    const [inputAuthorId, setInputAuthorId] = useState('');
    const [inputUserName, setInputUserName] = useState('');
    const [selectUsers, setSelectUsers] = useState();
    const [timeoutGetUser, setTimeoutGetUser] = useState(null);
    const [showUserDialog, setShowUserDialog] = useState(false);
    const [disabledCreate, setDisabledCreate] = useState(false);
    const [isChecked, setIsChecked] = useState(true);


    const handleUserNameChange = (e) => {
        let userName = e.target.value;
        setInputUserName(userName);

        if(userName) {
            if(timeoutGetUser){
                clearTimeout(timeoutGetUser);
            }
            setTimeoutGetUser(
                setTimeout(() => {
                    getUsers(userName);
                }, 1000)
            )
        } else {
            if(timeoutGetUser){
                clearTimeout(timeoutGetUser);
            }
            setShowUserDialog(false);
        }
    };

    const getUsers = async (userName) => {
        try {
            let res = await articleUsers(userName)
            if(res.code === 0){
                setSelectUsers(res.data);
                setShowUserDialog(true);
            } else {
                alert(res.msg)
            }
        } catch (error) {
            alert('获取失败')
            console.log(error)
        }
    };

    const handleSelectUsers = (selectUser) => {
        setInputAuthorId(selectUser.id);
        setInputUserName(selectUser.name);
        setShowUserDialog(false);
    }

    const handleInputChange = (event) => {
        setInputTitle(event.target.value);
    };

    const handleIsCheckedChange = (event) =>{
        setIsChecked(event.target.checked);
    };

    const handleOriginChange = (event) => {
        setInputOriginUrl(event.target.value);
    };

    const handleAuthorIdChange = (event) => {
        setInputAuthorId(event.target.value);
    };

    async function create() {
        setDisabledCreate(true);
        if(inputTitle && value){
            let res = await articleCreate({
                originUrl: inputOriginUrl,
                title: inputTitle,
                content: value,
                authorId: inputAuthorId,
                proofread: isChecked,
                type: createType
            })
            if(res.code === 0){
                localStorage.removeItem("translator-title");
                localStorage.removeItem("translator-originUrl");
                alert("发布成功")
                setInputAuthorId(null);
                setInputUserName(null);
                setShowCreateDialog(false);
                setDisabledCreate(false);
                setInputOriginUrl(null);
                setInputTitle(null);
            } else {
                alert(res.msg)
            }
        } else {
            alert("文章标题或内容不能为空")
        }

        setDisabledCreate(false);
    }

    return (
            <div className="mask">
                <div className="mask-container">
                    <button className="close" onClick={() => setShowCreateDialog(false)}>X</button>
                    <div className="mask-content">
                        <h3 style={{
                            fontWeight: "bold",
                            textAlign: "left",
                            marginLeft: "64px"
                        }}>{createType === 2 ? "翻译发布" : "转载发布"}</h3>
                        <div style={{
                            fontWeight: "bold"
                        }}>文章标题</div>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-around",
                            marginTop: '15px',
                            marginInline: "20px",
                            gap: "18px",
                        }}>
                            <input style={{
                                height: '22px',
                                width: '80%',
                                padding: '8px',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                            }} 
                            type="text"
                            value={inputTitle}
                            onChange={handleInputChange}/>
                        </div>
                        <div style={{
                            fontWeight: "bold",
                            marginTop: '15px'
                        }}>原文链接</div>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-around",
                            marginTop: '15px',
                            marginInline: "20px",
                            gap: "18px",
                        }}>
                            <input style={{
                                height: '22px',
                                width: '80%',
                                padding: '8px',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                            }} 
                            type="text"
                            value={inputOriginUrl}
                            onChange={handleOriginChange}/>
                        </div>
                        <div style={{
                            fontWeight: "bold",
                            marginTop: '15px'
                        }}>用户名</div>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-around",
                            marginTop: '15px',
                            position: 'relative',
                            marginInline: "20px",
                            gap: "18px",
                        }}>
                            <input style={{
                                height: '22px',
                                width: '80%',
                                padding: '8px',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                            }} 
                            type="text"
                            value={inputUserName}
                            onChange={handleUserNameChange}/>

                            {showUserDialog && selectUsers.length > 0 && (
                                <div className="selectUsersModal">
                                    {selectUsers.map((selectUser) => (
                                        <div className="selectUserItem" key={selectUser.id} onClick={() => handleSelectUsers(selectUser)}>
                                            <img style={{ height: '24px', borderRadius: '50%', objectFit: 'contain' }} src={`https://learnblockchain.cn/image/avatar/${selectUser.id}_middle.jpg`} alt="" />
                                            {selectUser.name}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: 'center',
                            marginTop: '15px',
                            marginInline: "20px",
                            gap: "18px",
                        }}>
                            <img style={{ height: '30px', borderRadius: '50%', objectFit: 'contain' }} src={`https://learnblockchain.cn/image/avatar/${inputAuthorId}_middle.jpg`} alt="" />
                            <input style={{
                                height: '22px',
                                width: '20%',
                                padding: '8px',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                            }}
                            placeholder="作者ID"
                            type="text"
                            value={inputAuthorId}
                            onChange={handleAuthorIdChange}/>
                        </div>
                        {createType === 2 && (
                            <label style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                marginTop: '20px',
                                marginLeft: '58px',
                                gap: "8px",
                                cursor: "pointer",
                                width: "max-content"
                            }}>
                                <input style={{ cursor: "pointer" }} type="checkbox" checked={isChecked} onChange={handleIsCheckedChange} />
                                <div style={{ fontWeight: "bold" }}>是否已校对</div>
                            </label>
                        )}
                        <button style={createType === 2 ? {marginTop: "20px"} : {marginTop: "30px"}} className="linkImport" disabled={disabledCreate} onClick={() => create()}>{disabledCreate ? "发布中" : "发布"}</button>
                    </div>
                </div>
            </div>
        )}