import { useEffect, useRef, useState } from 'react';
import './App.css';
import { EditorPreview, EditorWrite } from './components/CustomEditor';
import { throttle } from './utils/throttle';

function App() {

  const editorRef = useRef(null);
  let [doms, setDoms] = useState([]);
  let [selectObj, setSelectObj] = useState();
  let [cursor, setCursor] = useState();
  const throttledScrollHandler = throttle((index, percent) => {
    onScorll(index, percent)
  }, 20);


  function changeSelectNum(params, cursorObj) {
    if (params) {
      selectObj = params;
      setSelectObj({...selectObj});
    }else{
      selectObj = null;
      setSelectObj(selectObj);
      cursor = cursorObj;
      setCursor({...cursor});
    }
  }

  function changeValue(value,subtitle) {
    editorRef.current.changeValue(value,subtitle)
  }

  function replacePictrue(value) {
    editorRef.current.onlyReplacePictrue(value)
  }

  function scrollSwitch(index) {
    if (editorRef.current.loading) {
      return
    }
    const selectDom = doms[index];
    const percent = (selectDom.scrollTop / selectDom.scrollHeight).toFixed(5);
    throttledScrollHandler(index, percent);
  }

  function onScorll(index, percent) {
    doms.forEach((dom, i) => {
      if (i !== index && dom.scrollHeight !== 0) {
        dom.scrollTop = dom.scrollHeight * percent
      }
    })
  }

  // 添加滚动条监听器
  useEffect(() => {
    doms = [
      document.querySelector(".Editor-before .CodeMirror-scroll"),
      document.querySelector(".Editor-before .bytemd-preview"),
      document.querySelector(".Editor-preview .CodeMirror-scroll"),
      document.querySelector(".Editor-preview .bytemd-preview"),
    ]
    setDoms([...doms])

    doms.forEach((dom, index) => {
      dom.addEventListener("scroll", () => {scrollSwitch(index)});
    })
    return () => {
      doms.forEach((dom, index) => {
        dom.removeEventListener("scroll", () => {scrollSwitch(index)});
      })
    }
  },[])

  return (
    <div className="App">
      <div 
        className="custom-editor"
        style={{
          width: "100%",
          display: "flex",
          overflow: "hidden"
        }}
      >
        <EditorWrite 
          changeValue={changeValue}   //  翻译
          replacePictrue={replacePictrue}     //  替换图片来源链接
          selectObj={selectObj} 
          cursor={cursor} 
          clearCursor={() => {setCursor(null);setSelectObj(null);}} 
        />
        <EditorPreview 
          ref={editorRef} 
          changeSelectNum={changeSelectNum} 
          selectObj={selectObj} 
        />
      </div>
    </div>
  );
}

export default App;
