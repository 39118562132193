const visit = require('unist-util-visit');


export function getAstNode(event) {
    const node = event.parentNode;
    if (node.className.indexOf("data-id-") === -1) {
      return getAstNode(node);
    }
    return node
}

// 获取taget类名相同的集合
export function getTargetArr(ast, targetClassName) {
    let targetArr = [];
    visit.visit(ast, (node) => {
        const lineNumber = node.position.start.line;
        const lineColumn = node.position.start.column;
        node.data = node.data || {};
        node.data.hProperties = node.data.hProperties || {};
        node.data.hProperties = {
          className: `data-id-${lineNumber} data-column-${lineColumn}`,
        }
      if (
        targetClassName.split(" ").some(e => e === node.data.hProperties.className.split(" ")[0])     //  判断行
      ) {
        targetArr.push(node)
      }
    })
    return targetArr;
}
    
export function astCodeBlock(codeBlock, y, height) {
    const selectBlock = codeBlock.position;
    const lines = selectBlock.end.line - selectBlock.start.line - 1;
    const lineHight = (height / lines);
    const blockline = parseInt(y / lineHight);

    return{
        line: selectBlock.start.line + blockline, 
        column: selectBlock.start.column
    }
}

export function addNode(params) {
    return (tree) => {
      visit.visit(tree, (node) => {
        if (node.position) {
            const lineNumber = node.position.start.line;
            const lineColumn = node.position.start.column;
            node.data = node.data || {};
            node.data.hProperties = node.data.hProperties || {};
            node.data.hProperties = {
              className: `data-id-${lineNumber} data-column-${lineColumn}`,
            }
        }
      });
    };
}