import "./styles";
import { Editor } from "@bytemd/react";
import { useEffect, useMemo, useRef, useState } from "react";
import {
    frontmatter,
    highlight,
    breaks,
    zh_Hans,
    gfm,
    importHtml,
} from "./editor";
import { useRequest } from 'ahooks';
import { createRoot } from 'react-dom/client';
import useDebounce from "../../hook/useDebounce";
import { getCache, saveCahce } from "../../utils/cache";
import CustomCreateModal from "../CustomCreateModal";
import { getLinkContent, translate } from "../../request/api";
import {
    addNode,
    astCodeBlock,
    getAstNode,
    getTargetArr,
} from "../../utils/preview";
import html from "remark-html";
const remark = require("remark");

export default function EditorWrite(
    { changeValue, replacePictrue, selectObj, cursor, clearCursor },
    ref
) {
    let [showCreateDialog, setShowCreateDialog] = useState();
    const [inputTitle, setInputTitle] = useState('');
    const [inputOriginUrl, setInputOriginUrl] = useState('');
    const [showImportDialog, setShowImportDialog] = useState(false);
    const [showTranslateDialog, setShowTranslateDialog] = useState(false);
    const [disabledImport, setDisabledImport] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const fileInput = useRef(null);
    const [value, setValue] = useState(getCache("left"));
    let [position, setPosition] = useState();
    let [modalPosition, setModalPosition] = useState({});
    const debouncedSave = useDebounce((nextValue) => {
        // 这里是你的保存逻辑
        saveCahce("left", value);
    }, 1000);

    const { run } = useRequest(onSelect, {
        debounceWait: 100,
        manual: true
    });

    const plugins = useMemo(() => [
        gfm(),
        frontmatter(),
        highlight(),
        breaks(),
        importHtml(),
        {
            actions: [
                {
                    title: "替换图片来源链接",
                    position: "right",
                    icon: "换图", // 16x16 SVG icon
                    handler: {
                        type: "action",
                        click(ctx, y) {
                            ctx.editor.focus();
                            replacePictrue(value);
                        },
                    },
                },
                {
                    title: "切换",
                    position: "right",
                    icon: "翻译", // 16x16 SVG icon
                    handler: {
                        type: "action",
                        click(ctx, y) {
                            ctx.editor.focus();
                            setShowTranslateDialog(prevState => !prevState);
                        },
                    },
                },
                {
                    title: "导入.md文件",
                    position: "right",
                    icon: "导入", // 16x16 SVG icon
                    handler: {
                        type: "action",
                        click(ctx, y) {
                            ctx.editor.focus();
                            setShowImportDialog(true);
                        },
                    },
                },
                {
                    title: "发布到社区",
                    position: "right",
                    icon: "发布", // 16x16 SVG icon
                    handler: {
                        type: "action",
                        click(ctx, y) {
                            ctx.editor.focus();
                            setInputTitle(localStorage.getItem("translator-title-untrans"));
                            setInputOriginUrl(localStorage.getItem("translator-originUrl"));
                            setShowCreateDialog(true);
                        },
                    },
                },
            ],
        },
        {
            viewerEffect: (ctx) => {
                ctx.markdownBody.addEventListener("dblclick", doubleClick);
                return () => {
                    ctx.markdownBody.removeEventListener("dblclick", doubleClick);
                };
            },
        },
        {
            editorEffect: (ctx) => {
                ctx.editor.on("cursorActivity", function(instance) {
                    cursorActivity(ctx.editor)
                })
                ctx.editor.getWrapperElement().addEventListener('contextmenu', function(e) {
                    const selectedText = ctx.editor.getSelection();
                    if (selectedText !== '') {
                        e.preventDefault();
                        const x = e.clientX; // 获取鼠标点击位置的X坐标
                        const y = e.clientY; // 获取鼠标点击位置的Y坐标

                        const startLine = ctx.editor.getCursor('from');
                        const endLine = ctx.editor.getCursor('to');

                        run({selectedText, x, y, startLine, endLine,}, ctx.editor)
                    }
                })
                const selectedText = ctx.editor.getSelection();
                if (selectObj && selectObj.type !== "editor") {
                    const { startLine, endLine } = selectObj;
                    ctx.editor.setSelection(
                        { line: startLine + 1, ch: -1 },
                        { line: endLine + 1, ch: -1 }
                    );
                    clearCursor();
                } else if (!selectObj && cursor && selectedText !== "") {
                    ctx.editor.setCursor(cursor);
                    clearCursor();
                }
                if (position) {
                    ctx.editor.focus();
                    ctx.editor.setCursor({
                        line: position.line - 1,
                        ch: position.column - 1,
                    });
                    ctx.editor.scrollIntoView(
                        { line: position.line, ch: position.column },
                        200
                    );
                    position = null;
                    setPosition(position);
                }
            },
        },
        {
            remark: (p) => p.use(addNode).use(html),
        },
    ]);

    async function getLinkValue() {

        setDisabledImport(true);

        const mdContent = await getLinkContent({
            url: inputValue,
        })

        if (mdContent.code === 0) {
            setValue(mdContent.data.content);
            setShowImportDialog(false);
            setDisabledImport(false);

            let res = await translate({
                origin: mdContent.data.title,
                translation: '',
                sourceLang: "en",
                targetLang: "zh-cn",
                service: "chatgpt",
            })
            localStorage.setItem("translator-title", res.translation)
            localStorage.setItem("translator-originUrl", inputValue)
        } else {
            alert(mdContent.msg)
            setDisabledImport(false);
        }
    }

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    // 双击事件
    function doubleClick(event) {
        // 选中
        const select = window.getSelection().toString();

        const dom = document.querySelectorAll(
            ".Editor-before .bytemd-toolbar-left .bytemd-toolbar-tab"
        )[0];
        dom.click();
        // 提取内容
        const target = event.target;
        const targetTag = target.localName;
        const targetClassName =
            targetTag === "pre"
                ? target.children[0].className
                : target.className; //  pre特殊处理
        const y = event.offsetY;
        const height =
            targetTag === "pre"
                ? target.children[0].offsetHeight
                : event.target.offsetHeight;
        // 获取当前ast树
        let ast = remark.remark.parse(value);

        // 获取taget类名相同的集合
        let targetArr = getTargetArr(ast, targetClassName);

        // 根据 targetArr length来判断当前点击的是什么
        if (targetArr.length > 1) {
            // 多行文本内的某一个 ===> 可以用column + value来判断
            const selectNode = targetArr.filter((node) =>
                targetClassName
                    .split(" ")
                    .some(
                        (e) =>
                            e ===
                                node.data.hProperties.className.split(" ")[1] &&
                            node.value
                    )
            );
            const selectBlock = selectNode[0].position;
            position = {
                line: selectBlock.start.line,
                column: selectBlock.start.column - 1,
            };
        } else if (targetArr.length === 1) {
            // 代码块
            const { line, column } = astCodeBlock(targetArr[0], y, height);
            position = { line: line, column: column };
        } else {
            // 代码块中未解析为node的元素
            const node = getAstNode(event.target);
            let targetArr = getTargetArr(ast, node.className);
            let height = node.offsetHeight;
            const { line, column } = astCodeBlock(targetArr[0], y, height);
            position = { line: line, column: column };
        }
        setPosition({ ...position });
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const regex = /\.(txt|md|csv|log|srt|sub|ssa|ass)$/;
        if (!regex.test(file.name)) {
            alert("请上传一个纯文本文件");
            return;
        }
        const reader = new FileReader();

        reader.onload = function (e) {
            setValue(e.target.result);
            // 在这里处理文件内容
        };

        reader.readAsText(file);

        setShowImportDialog(false);

        // 在这里处理文件上传
    };

    function cursorActivity(cmInstance) {
        var selectedText = cmInstance.getSelection();
        if (selectedText === "") {
            removeHtml();
        }
    }

    function onSelect({selectedText, x, y, startLine, endLine}, editor) {
        // 移除前一个
        removeHtml()
        addHtml({selectedText, x, y}, (text)=> {
            const str = text.replace(/\n$/, '');

            editor.replaceRange(text, {line: startLine.line, ch: startLine.ch}, {line: endLine.line, ch: endLine.ch})
            removeHtml();
        });
    }

    function removeHtml() {
        const doms = document.querySelectorAll(".modal-operate");
        if (doms.length !== 0) {
            doms.forEach(dom => {
                dom.remove();
            })
        }
    }

    function addHtml({selectedText, x, y}, replace) {
        const modal = document.createElement("div");
        const face = (x + 200 + 300) > window.innerWidth;

        modalPosition = { x: face ? x-540 : x+200, y };
        setModalPosition({...modalPosition});
        const root = createRoot(modal);
        root.render(Modal({x: face ? x - 200 : x, y, selectedText}, replace));
        document.querySelector(".custom-editor").appendChild(modal)
    }

    const Modal = ({x, y, selectedText}, replace) => {
        async function stringReplace(selectedText) {
            replace(selectedText
                .replace(/\\=/g, "=")
                .replace(/\\_/g, "_")
                .replace(/\\\*/g, "*"))
        }
        return (
            <div 
                className="modal-operate" 
                style={{
                    left: x,
                    top: y
                }}
            >
                {/* 正文 */}
                <div className="modal-content">
                    <button onClick={() => stringReplace(selectedText)}>转义替换</button>
                </div>
            </div>
        )
    }

    useEffect(() => {
        debouncedSave();
    }, [value]);

    return (
        <div className="Editor Editor-before">
            <input
                type="file"
                ref={fileInput}
                onChange={handleFileChange}
                style={{ display: "none" }}
            />
            {showImportDialog && (
                <div className="mask">
                    <div className="mask-container">
                        <button className="close" onClick={() => setShowImportDialog(false)}>X</button>
                        <div className="mask-content">
                            <div style={{
                                fontWeight: "bold"
                            }}>链接导入</div>
                            <div style={{
                                display: "flex",
                                justifyContent: "space-around",
                                marginTop: '15px',
                                marginInline: "20px",
                                gap: "18px",
                            }}>
                                <input style={{
                                    height: '22px',
                                    width: '80%',
                                    padding: '8px',
                                    borderRadius: '4px',
                                    border: '1px solid #ccc',
                                }} 
                                type="text"
                                value={inputValue}
                                onChange={handleInputChange}/>
                                <button className="linkImport" disabled={disabledImport} onClick={() => getLinkValue()}>确认</button>
                            </div>

                            <div style={{
                                fontWeight: "bold",
                                marginTop: '20px',
                                marginBottom: '15px',
                            }}>纯文本文件导入</div>

                            <button className="linkImport" onClick={() => fileInput.current.click()}>选择文件</button>
                        </div>
                    </div>
                </div>
            )}
            {showCreateDialog && (
                <CustomCreateModal
                    inputTitle={inputTitle}
                    setInputTitle={setInputTitle}
                    inputOriginUrl={inputOriginUrl}
                    setInputOriginUrl={setInputOriginUrl}
                    setShowCreateDialog={setShowCreateDialog}
                    value={value}
                    createType={3}
                />
            )}
            {showTranslateDialog && (
                <div className="translate">
                    <div onClick={() => { changeValue(value, 0); setShowTranslateDialog(false); }}>翻译</div>
                    <div onClick={() => { changeValue(value, 1); setShowTranslateDialog(false); }}>字幕</div>
                    <div onClick={() => { changeValue(value, 2); setShowTranslateDialog(false); }}>双语</div>
                </div>
            )}
            <Editor
                mode="tab"
                locale={zh_Hans}
                value={value}
                plugins={plugins}
                sanitize={(schema) => {
                    // replace '*' to the tag name if you want it only takes effect with a specified tag
                    schema.attributes["*"].push("data-*");
                    // console.log("schema ===>", schema);
                    return schema;
                }}
                onChange={(v) => {
                    setValue(v);
                }}
            />
        </div>
    );
}
