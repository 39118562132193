


export function getAllStrIndexes(str, val, {isFullChar, isCaseSensitive}) {
    var indexes = [], i = -1;

    var regexFlags = isCaseSensitive ? "g" : "gi";
    var regexPattern = isFullChar ? "\\b" + val + "\\b" : val;
    var regex = new RegExp(regexPattern, regexFlags);
    let match;
    while ((match = regex.exec(str)) != null) {
        indexes.push(match.index);
    }
    return indexes;
}