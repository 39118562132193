import { useState } from "react";
import "./index.scss";

export default function CustomSwitch({checked, toggleSwitch}) {
    
    // const [checked, setChecked] = useState(false);

    // const toggleSwitch = () => {
    //   setChecked(!checked);
    // };

    return (
        <div className={`switch ${checked ? "on" : "off"}`} onClick={toggleSwitch}>
            <div className="slider" />
        </div>
    )
}