

export function saveCahce(key, value) {

    if (key === "left") {
        localStorage.setItem("translator-cache-before", value);
    }else {
        localStorage.setItem("translator-cache-after", value);
    }
    const timestamp = new Date().getTime() + 86400000;      //  一天过期
    localStorage.setItem("translator-cache-time", timestamp)
}


export function getCache(key) {
    const leftStr = localStorage.getItem("translator-cache-before") || "";  //  左侧
    const rightStr = localStorage.getItem("translator-cache-after") || "";  //  右侧
    const lastTime = localStorage.getItem("translator-cache-time");

    const timestamp = new Date().getTime();

    const str = key === "left" ? leftStr : rightStr;
    if (!lastTime || timestamp > lastTime) {
        localStorage.setItem("translator-cache-time", null);
        localStorage.removeItem("translator-cache-after");
        localStorage.removeItem("translator-cache-before");
        return ""
    }else{
        return str
    }
}